import {useEffect, useState} from "react";
import {Coordinate, RegionSlug} from "src/store/types";

import {Address} from "../_services/types";
import {useGeolocation} from "../utils/browser-storage/location";
import {
  setUserSelectedLocation,
  useUserSelectedLocation,
} from "src/utils/browser-storage/userSelectedLocation";
import {roundedMilesBetweenCoordinates} from "../utils/distance";

export const addDistToLocsByPoint = <T extends {address: Address} | {x: number; y: number}>(
  location: T,
  point: Coordinate,
  decimalPlaces = 0,
) => {
  const locationPos = {
    x: "x" in location ? location.x : location.address.latitude,
    y: "y" in location ? location.y : location.address.longitude,
  };
  return {
    ...location,
    dist: roundedMilesBetweenCoordinates(
      point.x,
      point.y,
      locationPos.x,
      locationPos.y,
      decimalPlaces,
    ),
  };
};

const sortLocationsByDist = <T extends {dist: number}>(a: T, b: T) => a.dist - b.dist;

export const sortLocationsByPoint = <T extends {address: Address} | {x: number; y: number}>(
  locations: T[],
  point: Coordinate,
): T[] => locations.map(loc => addDistToLocsByPoint(loc, point)).sort(sortLocationsByDist);

type WithRegion<T> = T & {region?: {slug: string}};

/**
 *
 * @param locations
 *
 * @returns `[sortedLocations, loading]`
 * `loading` is true until the first sort is complete.

 */
export const useSortLocations = <
  T extends
    | WithRegion<{
        address: Address;
      }>
    | WithRegion<{
        x: number;
        y: number;
      }>,
>(
  locations: T[],
) => {
  const [locationsSorted, setLocationsSorted] = useState(false);
  const [state, setState] = useState(locations);
  const geolocation = useGeolocation();
  const {x, y} = geolocation || {};
  const {regionSlug: selectedRegionSlug} = useUserSelectedLocation();

  useEffect(() => {
    if (x && y) {
      const sortedLocations = sortLocationsByPoint(locations, {
        x,
        y,
      });
      setState(sortedLocations);
      setLocationsSorted(true);

      // If there is no selected region slug, set it to the region of the closest location
      const closestRegionSlug = sortedLocations[0]?.region?.slug as RegionSlug | undefined;
      if (!selectedRegionSlug && closestRegionSlug) {
        setUserSelectedLocation({regionSlug: closestRegionSlug});
      }
    }
  }, [locations, selectedRegionSlug, x, y]);

  return [state, locationsSorted] as const;
};
